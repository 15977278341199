/* Login background style */
.login-container {
    height: 100vh;
    max-width: 100vw !important;
    background-color: #f16663;
}

/* Login form box style */
#login-form {
    padding: 1rem 2rem;
    margin: 0;
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 8px black;
    border-radius: 10px;
}

/* Login input field style */
.MuiFormControl-root.login-input {
    width: 100%;
    margin-bottom: 1.2rem;
}

/* Forgot password text style */
.MuiTypography-root.forgot-password-link {
    display: block;
    margin-left: auto;
    text-align: right;
}
.MuiTypography-colorPrimary.forgot-password-link {
    color: brown;
}

/* Login button style */
#login-btn {
    display: block;
    width: 100%;
    margin: 2rem 0 2rem 0;
    font-weight: bold;
    color: white;
    background-color: #f16663;
    font-family: 'Rubik';
}

/* Login logo style */
.login-logo {
    width: inherit;
    height: 80px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 10px;
}

@media (max-width: 479px) {
    #login-form {
        width: 60vw;
        padding: 0 1.5rem;
    }
    #login-btn {
        margin-top: 1rem;
    }
    .login-logo {
        margin-bottom: -10px;
    }
    .login-input .MuiInput-root .MuiInputAdornment-root .MuiSvgIcon-root {
        font-size: 1.3rem;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    #login-form {
        width: 55vw;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {

}

@media (min-width: 1024px) and (max-width: 1279px) {
    
}