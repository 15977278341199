/* Style for default input field */
.MuiTypography-caption {
    color: #592c0d;
}
.MuiInputBase-input, h1 {
    color: #592c0d !important;
    font-family: 'Rubik' !important;
}
h2 {
    font-family: 'Rubik';
    color: #592c0d;
}

/* Make item full width */
.full-width {
    width: 100%;
}

/* Style to hide element */
.hide {
    display: none !important;
}

/* Style for margin */
.add-margin-bottom {
    margin-bottom: 20px !important;
}
.no-margin-bottom {
    margin-bottom: 0;
}

/* Burger button style */
.responsive-header h2 {
    display: none;
}
.responsive-header img {
    display: none;
}
.menu-icon-btn {
    display: none;
}

/* Default color for input field on focus */
.MuiFormLabel-root.Mui-focused {
    color: #592c0d !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #592c0d !important;
}

/* Style for default link */
.default-link-style {
    color: white;
    text-decoration: none;
}

/* Dashboard content style */
.content {
    min-height: 100vh;
    margin-left: 300px;
    background-color: #F0F0F0;
}
.grid-container {
    padding: 50px;
}

/* Style for add & cancel button */
.MuiButtonBase-root.MuiButton-root.MuiButton-containedPrimary {
    background-color: #f16663; 
}
.add-new-btn.MuiButton-root {
    margin-top: 50px;
    font-weight: bold;
    color: white;
    background-color: #f16663;
    min-width: 120px;
}
.add-new-btn.MuiButton-root:hover {
    background-color: #f17d81;
}
.add-cancel-btn.MuiButton-root {
    margin-top: 50px;
    font-weight: bold;
    float: right;
    min-width: 120px;
    color: #592c0d;
}
.add-btn-table.MuiButton-root {
    font-family: 'Rubik Bold';
    color: white;
    background-color: #f16663;
    padding-top: 5px;
    padding-bottom: 5px;
}
.add-btn-table.MuiButton-root:hover {
    background-color: #f17d81;
}

/* Error style for required field */
.text-required {
    color: red;
    margin-top: -15px;
    text-align: left;
}
.text-required-half {
    color: red;
    margin-top: -15px;
    float: left;
}
.text-required-half.half-right {
    margin-left: 5%;
}
.required-info {
    margin-top: 60px;
    font-weight: bold;
    color: #592c0d;
    font-family: 'Rubik';
}

/*Table*/
.material-table .MuiToolbar-root {
    padding: 0;
}
.material-table .MuiToolbar-root .MuiTypography-h6 {
    color: white;
    padding-left: 15px;
    font-family: 'Rubik';
}
.material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
.material-table .MuiToolbar-root .jss10,
.material-table .MuiToolbar-root [class*="jss"].MuiTextField-root {
    padding-right: 15px;
}
.material-table .MuiTable-root .MuiTableFooter-root {
    /* box-shadow: 0px -1px 3px gray; */
    border-top: solid 3px lightgray;
}

.MuiTable-root thead tr th {
    font-weight: bold;
    text-align: left !important;
}
.MuiToolbar-regular {
    background-color: #f16663;
}
.MuiTableCell-paddingCheckbox:last-child {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.table-footer.MuiGrid-root {
    margin-top: -45px;
    padding-left: 15px;
}
[class*="MTableToolbar-searchField"] .MuiInput-underline::before, .jss10 .MuiInput-underline::before,
[class*="jss"].MuiTextField-root .MuiInput-underline::before,
[class*="MTableToolbar-searchField"] .MuiInput-underline::after, .jss10 .MuiInput-underline::after,
[class*="jss"].MuiTextField-root .MuiInput-underline::after {
    border: none;
}
.MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
    background-color: white;
}
.MuiTablePagination-input, .MuiTableCell-head, .MuiTableCell-root {
    color: #592c0d !important;
    font-family: 'Rubik' !important;
}
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Style for all classes that contain "MTableToolbar-searchField" */
[class*="MTableToolbar-searchField"] .MuiInput-underline,
.jss10 .MuiInputBase-root.MuiInput-root.MuiInput-underline,
[class*="jss"].MuiTextField-root .MuiInputBase-root.MuiInput-root.MuiInput-underline  {
    background-color: white;
    padding-left: 10px;
    border-radius: 3px;
    margin-right: 10px;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar {
    /* [CHROME] width of the entire scrollbar */
    width: 7px;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-track {
    /* [CHROME] color of the tracking area */
    background: #F0F0F0;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-thumb {
    background-color: #f16663;    /* [CHROME] color of the scroll thumb */
    border-radius: 20px;            /* [CHROME] roundness of the scroll thumb */
}

/* Style for legend */
legend span {
    font-family: 'Rubik';
}

/* Style for switch / toggle button */
.MuiSwitch-colorSecondary.Mui-checked {
    color: #f16663 !important;
}
.MuiSwitch-track {
    background-color: #f16663 !important;
}

/* Style when page editing is disabled */
.disabled-border:hover {
    border-color: rgba(0, 0, 0, 0.28) !important;
}

@media (max-width: 479px) {
    .grid-container {
        padding: 30px 30px;
    }
    .responsive-header img, .responsive-header h2 {
        display: inherit;
    }
    .responsive-header.MuiGrid-container {
        color: white;
        background-color: #f16663;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: white;
        border-color: white;
    }
    .content {
        margin-left: 0;
    }
    .header-logo {
        width: 100%;
        height: 80px;
        object-fit: cover;
        object-position: center;
        padding-left: 30px;
    }
    .material-table .MuiToolbar-root {
        display: block;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
        padding: 10px 5px;
        width: auto;
    }
    .MuiTableFooter-root [class*="MTablePaginationInner-root"] .MuiIconButton-root {
        padding: 5px;
    }
    .MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
        overflow: hidden;
    }
    .material-table .MuiTableFooter-root .MuiTablePagination-input {
        display: flex;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .material-table .MuiToolbar-root .jss10,
    .material-table .MuiToolbar-root [class*="jss"].MuiTextField-root {
        padding: 0;
        padding-bottom: 1rem;
        padding-left: 8px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"]
        .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root .jss10 .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root [class*="jss"].MuiTextField-root .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-left: 0;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1.5rem;
        padding: 1rem 0;
        text-align: center;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .grid-container {
        padding: 3rem;
    }
    .responsive-header img, .responsive-header h2 {
        display: inherit;
    }
    .responsive-header.MuiGrid-container {
        color: white;
        background-color: #f16663;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: white;
        border-color: white;
    }
    .content {
        margin-left: 0;
    }
    .header-logo {
        width: 100%;
        height: 80px;
        object-fit: cover;
        object-position: center;
        padding-left: 30px;
    }
    .material-table .MuiToolbar-root {
        display: block;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
    .MuiTableFooter-root .MuiTablePagination-input .MuiSelect-select.MuiSelect-select {
        padding: 10px 5px;
        width: auto;
    }
    .MuiTableFooter-root [class*="MTablePaginationInner-root"] .MuiIconButton-root {
        padding: 5px;
    }
    .MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters {
        overflow: hidden;
    }
    .material-table .MuiTableFooter-root .MuiTablePagination-input {
        display: flex;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
    .material-table .MuiToolbar-root .jss10,
    .material-table .MuiToolbar-root [class*="jss"].MuiTextField-root {
        padding: 0;
        padding-bottom: 1rem;
        padding-left: 8px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"]
        .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root .jss10 .MuiInputAdornment-positionEnd .MuiIconButton-root,
    .material-table .MuiToolbar-root [class*="jss"].MuiTextField-root .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-left: 0;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        font-size: 1.5rem;
        padding: 1rem 0;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content {
        margin-left: 200px;
    }
    .grid-container {
        padding: 35px;
    }
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
}

@media (max-width: 1279px) {
    .delete-img-btn {
        display: none;
    }
}
@media (min-width: 1024px) {
    .MuiTableRow-root {
        width: max-content;
        margin-left: auto;
    }
}