.pink-bg {
    background-color: #f16663 !important;
}
.pink-bg:hover {
    background-color: #f17d81 !important;
}

.pink-text {
    color: #f16663 !important;
}

.default-text {
    color: #592c0d !important;
}