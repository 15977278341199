/* Style for form in Modal */
.custom-form .MuiFormControl-root {
    display: block;
    margin-bottom: 20px;
}
.modal-cancel-btn {
    float: right;
}
.modal-body {
    top: 50%;
    left: 50%;
    width: 30vw;
    position: absolute;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 3px 3px 3px black;
    padding: 20px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
}
.jwt-title {
    text-align: center;
}
.jwt-text {
    text-align: center;
}
.MuiButtonBase-root.jwt-btn {
    display: block;
    margin: 1.5rem auto 1rem;
    background: #f16663;
    color: white;
    font-family: 'Rubik';
    font-weight: bold;
}
.MuiButtonBase-root.jwt-btn:hover {
    background: #f17d81;
}

/* Style for form layout */
.add-form {
    background-color: white;
    padding: 1rem 3rem 3rem;
    border-radius: 5px;
}
.image-form {
    padding: 1rem 1rem 3rem;
    border: solid 1px rgba(0, 0, 0, 0.22);
    border-radius: 5px;
    margin-bottom: -15px;
}

/* Style for error on validation */
.error-style .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}
.error-style .MuiOutlinedInput-notchedOutline {
    border-color: red;
}

/* Style for half-width input field */
.form-left-field {
    width: 95%;
    float: left;
    margin-bottom: 20px !important;
}
.form-right-field {
    width: 95%;
    float: right;
    margin-bottom: 20px !important;
}

/* Style for switch / toggle form */
.MuiFormControl-root.switch-layout {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    padding: 5px 0;
    width: 100%;
}
.MuiFormControl-root.switch-layout:hover {
    border-color: black;
}
.MuiFormLabel-root.legend-switch {
    margin-left: 8px;
    padding: 0 5px;
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Rubik';
}
.MuiFormControlLabel-root.fcl-switch {
    padding-left: 10px;
}
.MuiFormControlLabel-root.fcl-switch .MuiTypography-root {
    color: #592c0d;
    font-family: 'Rubik';
}

/* Style for input price */
.price-field {
    padding: 17px 13px 14px;
    font-size: 1rem;
    color: #592c0d;
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.23);
    width: -webkit-fill-available;
    width: -moz-available;
}
.price-field:hover {
    border-color: #592c0d;
}
.price-label, .grouped-label {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    margin-top: -10px;
    position: absolute;
    margin-left: 8px;
    padding: 0 6px;
    z-index: 100;
}

/* Style for grouped field */
.grouped-field [class*="control"] {
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.grouped-field [class*="control"]:hover {
    border-color: #592c0d !important;
}
.grouped-field .css-1pahdxg-control {
    border-color: #592c0d;
    box-shadow: 0 0 0 1px #592c0d;
}
.grouped-field [class*="placeholder"] {
    font-family: 'Rubik';
    color: #592c0d77;
}
.grouped-field [class*="control"] [class*="ValueContainer"] [class*="multiValue"] {
    font-family: 'Rubik';
    border-radius: 15px;
    padding: 3px 6px;
    font-size: 0.9rem;
}
.grouped-field [class*="control"] [class*="ValueContainer"] [class*="multiValue"] div {
    color: #592c0d;
}

/* Style for input tags */
[class*="chipContainer"] .MuiChip-root .MuiChip-label {
    font-family: 'Rubik';
    color: #592c0d;
}
.tag-info {
    color: #592c0d;
    font-size: smaller;
    font-family: 'Rubik';
    margin-bottom: 20px;
}

/* Style for selection label */
.tf-label label {
    padding: 0 3px;
    background-color: white;
}

/* Style for edit button in detail page */
.edit-btn.MuiButton-root {
    font-family: 'Rubik';
    background-color: #f16663;
    color: white;
    font-weight: bold;
    margin-top: 1.5rem;
    float: right;
}
.edit-btn.MuiButton-root:hover {
    background-color: #f17d81;
}
.edit-gallery-btn.MuiButtonBase-root {
    background-color: #f16663;
    color: white;
    font-family: 'Rubik Bold';
    float: right;
}
.edit-gallery-btn.MuiButtonBase-root:hover {
    background-color: #f17d81;
}

/* Style for image uploader */
.browse-btn {
    width: 28%;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    border: solid 1px darkgray;
    background-color: #f16663;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.browse-btn:hover {
    background-color: #f17d81;
}
.img-name {
    width: 59%;
    display: inline-block;
    color: #592c0d;
    padding: 10px 5px;
    border: solid 1px darkgray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    vertical-align: top;
}
.image-info {
    padding-left: 10px;
    font-family: 'Rubik';
}
.delete-img-btn {
    position: relative;
}
.delete-img-btn .MuiSvgIcon-root {
    position: absolute;
    top: 5px;
    right: 65px;
    color: #F0F0F0;
}
.can-delete {
    display: initial !important;
    cursor: pointer;
}
.cannot-delete {
    display: none !important;
    cursor: default;
}
.input-img-terms {
    margin-top: 0;
    color: #592c0d;
    font-family: 'Rubik';
}

/* Style for full width input image */
.full-input-image .delete-img-btn {
    right: 285px;
}
.full-input-image .image-info {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}

/* Style for input image */
.half-input-image {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.half-input-image .delete-img-btn {
    right: 15px;
}
.half-input-image .image-info {
    padding-left: 15px;
}

/* Style for product images section */
.product-gallery {
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 25px;
    border: solid 1px rgba(0, 0, 0, 0.22);
    border-radius: 3px;
}
.product-gallery h2 {
    margin: 0;
    margin-bottom: 10px;
    color: #592c0d;
    font-family: 'Rubik';
}
.MuiButton-root.delete-btn-gallery {
    margin: 8px 11px;
    display: block;
    width: 88%;
    background-color: #f17d81 !important;
}

/* Style for text editor */
.rdw-editor-wrapper {
    border: solid 1px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
}
.rdw-editor-wrapper:hover {
    border-color: #592c0d;
}
.rdw-editor-main {
    padding-left: 10px;
    padding-right: 10px;
}
.rdw-editor-wrapper .rdw-option-wrapper,
.rdw-editor-wrapper .rdw-dropdown-wrapper {
    border-color: rgba(0, 0, 0, 0.22);
}

/* Style for disabled field */
.disabled-field {
    background: none !important;
}
.disabled-field [class*="control"] {
    cursor: default;
    pointer-events: none;
}
.disabled-field:hover, .disabled-field [class*="control"]:hover {
    border-color:rgba(0, 0, 0, 0.22) !important;
}
.disabled-field [class*="multiValue"] svg,
.disabled-field [class*="chipContainer"] .MuiChip-deleteIcon {
    display: none;
}
.disabled-wrapper:hover {
    border-color:rgba(0, 0, 0, 0.22) !important;
}

@media (max-width: 479px) {
    .add-form {
        padding: 1rem 1.5rem 3rem;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .add-form h2 {
        text-align: center;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .full-input-image .image-info, .half-input-image {
        width: 100%;
    }
    .img-name {
        width: 57%;
    }
    .browse-btn {
        width: 30%;
    }
    .image-form {
        padding: 1rem 1rem 0;
    }
    .text-required-half.half-right {
        margin-left: 0;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .input-img-terms {
        text-align: center;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .product-gallery .MuiGrid-item h2 {
        text-align: center;
    }
    .modal-body {
        width: 60vw;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .add-form {
        padding: 1rem 2rem 3rem;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .add-form h2 {
        text-align: center;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .text-required-half.half-right {
        margin-left: 0;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .input-img-terms {
        text-align: center;
    }
    .modal-body {
        width: 50vw;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .add-form {
        padding: 1rem 2rem 3rem;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .add-form h2 {
        text-align: center;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .modal-body {
        width: 40vw;
    }
}