.sidebar {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    box-shadow: 5px 5px 5px gray;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
    display: none;
}

/* Sidebar logo style */
.sidebar-logo {
    width: 100%;
    height: 80px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
}

.sidebar-header {
    padding: 0.5rem;
    text-align: center;
}

.sidebar .sidebar-item .MuiSvgIcon-root {
    pointer-events: none;
}

/* Sidebar item & sub-item style */
.MuiListItem-button.sidebar-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #592c0d;
}
.MuiListItem-button.sidebar-item:hover {
    color: white !important;
    background-color: #f16663 !important;
}
.MuiListItem-button.sidebar-item:hover .MuiListItemIcon-root.sidebar-icon {
    color: white;
}
.sidebar-item-text .MuiTypography-root {
    font-size: large;
    font-family: 'Rubik Bold';
}
.MuiListItemIcon-root.sidebar-icon {
    color: #592c0d;
    margin-right: -15px;
}

/* Style for current active link */
.active-link .sidebar-item {
    background-color: #f16663 !important;
}
.active-link .MuiTypography-root {
    color: white !important;
}
.active-link .MuiListItemIcon-root.sidebar-icon {
    color: white;
}

@media (max-width: 479px) {
    .sidebar {
        width: 60%;
        box-shadow: 5px 0 10px grey;
    }
    .sidebar .sidebar-item .MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: 1rem;
    }
    .MuiCollapse-container .MuiListItemText-root .MuiTypography-displayBlock {
        font-size: 0.9rem;
    }

    .sidebar {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
    .slide-in {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .slide-out {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }

    /* Animation for sidebar in & out */
    @keyframes slide-in {
        100% { transform: translateX(0%); }
    }
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(0%); }
    }   
    @keyframes slide-out {
        0% { transform: translateX(0%); }
        100% { transform: translateX(-100%); opacity: 0;}
    }
    @-webkit-keyframes slide-out {
        0% { -webkit-transform: translateX(0%); }
        100% { -webkit-transform: translateX(-100%); }
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .sidebar {
        width: 50%;
        box-shadow: 5px 0 10px lightgrey;
    }
    .sidebar .sidebar-item .MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: 1rem;
    }
    .MuiCollapse-container .MuiListItemText-root .MuiTypography-displayBlock {
        font-size: 0.9rem;
    }

    .sidebar {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
    .slide-in {
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
    .slide-out {
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
    }

    /* Animation for sidebar in & out */
    @keyframes slide-in {
        100% { transform: translateX(0%); }
    }
    @-webkit-keyframes slide-in {
        100% { -webkit-transform: translateX(0%); }
    }   
    @keyframes slide-out {
        0% { transform: translateX(0%); }
        100% { transform: translateX(-100%); opacity: 0;}
    }
    @-webkit-keyframes slide-out {
        0% { -webkit-transform: translateX(0%); }
        100% { -webkit-transform: translateX(-100%); }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .sidebar {
        width: 200px;
    }
    .sidebar-item-text .MuiTypography-root {
        font-size: inherit;
    }
}