#bg-spinner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
}

#load-spinner {
    top: 50%;
    left: 50%;
    position: absolute;
}

#load-spinner svg {
    color: white;
}