@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    src: local('Rubik'), url('Rubik-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Rubik Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Rubik Bold'), url('Rubik-Bold.ttf') format('truetype');
}